<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-36 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Our Story
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 gap-4 mb-12">
        <img class="w-full rounded h-24 sm:h-48 md:h-full object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/alex+g.jpeg" alt="">
        <img class="w-full rounded h-24 sm:h-48 md:h-full md:col-span-2 md:row-span-2 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/homeplan-team-1.jpg" alt="Homeplan team photo 1">
        <img class="w-full rounded h-full md:col-span-2 md:row-span-2 object-cover" src="@/assets/story/homeplan-team-2.jpg" alt="Homeplan team photo 2">
        <img class="w-full rounded h-full object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/FGhZYVlXEAcbViP.jpeg" alt="">
        <img class="bg-gray-300 w-full rounded h-full object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/zach.jpg" alt="">
        <img class="bg-gray-300 w-full rounded h-full object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/people/signal-2022-04-08-10-08-27-245.jpg" alt="">
      </div>

      <div class="text-blue-900 font-bold mb-4 text-xl">Origins - Helping a neighbor</div>
      <div class="text-blue-900 text text-lg">
        Bash and Alex have been around real estate most of their lives as the sons of a former architectural drafter and software engineer for a mortgage company. 
        However, neither expected to start a real estate technology company.
        Some time around late 2019, Bash was asked by a neighbor of his mother to help him inside his home as he returned from hip replacement surgery. 
        The neighbor, Kevin, was a long time resident of the neighborhood, veteran and recent retiree living on a fixed income.
        Kevin was known around the neighborhood as a DIY guru. He had previously installed his own roof and siding, built a deck and even his own scaffolding. 
        Upon entering his home, Bash noticed trash cans on a fold-out table and incorrectly assumed they were for remodeling.
        Informed instead they were to catch the rain as his roof had begun to leak quite some time ago, he sought out the alderwoman at a neighborhood meeting where he was given information for a city program that helps homeowners in opportunity zones.

        <br/><br/>

        Kevin was presented with the information and application. 
        After review and approval, he was granted a new roof, fascia and gutters for FREE. 
        More neighbors soon asked Bash for help with issues around their homes.
      </div>


      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 pt-24 pb-12 gap-4">
        <img class="w-full rounded h-full md:col-span-2 md:row-span-2 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-1/FCtvuplWEAgT7lQ.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-1/FCtvvpqXEAAAUrj.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-1/FCtvwIpXMAoygzC.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-1/FCtvvUsXIBE-aVH.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-1/FCtvu_oXsAAxAsw.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-0/FCtvsreWYAIKjpP.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-0/FCtvtFTXoAA4FQC.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-0/FCtvtSTWYAIqOEX.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/jane-0/FCtvuK8XIAEieZl.jpeg" alt="">
      </div>


      <div class="text-blue-900 font-bold mb-4 text-xl">A homeowner's insurance story</div>
      <div class="text-blue-900 text text-lg">
        Shortly after helping Kevin get a new roof, Bash was visiting a friend's mother's home when the mother asked him for help understanding her insurance claim. 
        Her roof, like Kevin's, had began to fail and was causing damage inside her home as well.
        The insurance adjuster sent a low-ball claim of $12k for a home with a slate roof and copper gutters.
        Bash got on her roof to survey the damage and quickly informed her not to deposit the ACV check sent from her insurance company.
        She needed to fight for total roof replacement as the roof was beyond repair.
        She asked him if he would handle the insurance claim on her behalf and he agreed, hiring a trusted roofing company and submitting supplement claims for five months before she was awarded a final claim of $53k which included a new slate roof, second floor house wrap and siding, interior plaster repair and new copper flashings.

        <br/><br/>

        During roof repair, her neighbor asked about it and was informed to contact Bash to help her cut down invasive honeysuckle trees in her backyard. 
        The neighbor had been quoted $9,000 to cut them down.
        Bash completed the work himself for $1,000 and the homeowner stated it was the first time in 20 years that she was able to have a clear line of sight between her yard and her neighbor's.
      </div>


      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 pt-24 pb-12 gap-4">
        <img class="w-full rounded h-full md:col-span-2 md:row-span-2 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/20201229_130545.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/IMG_20220104_145538.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/IMG_20211224_132442648.jpg" alt="">
        <img class="w-full rounded h-full col-span-2 row-span-2 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-1/FHzei4cUYAIuHbX.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/FCtjTy8XIAEZ30t.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-1/FHzg7WvVIAUDS63.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/IMG_20211229_124611.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/FCtjVSrWEAY_vzh.jpeg" alt="" />
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-0/FFoT-lQXEBA6Trr.jpeg" alt="" />
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-1/FHzelweUUAAcMdn.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-1/FIS4pf7VEAAA6Yo.jpeg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="https://homeplan-assets.s3.us-east-2.amazonaws.com/photos/our-story/alex-1/IMG_20220104_145553.jpg" alt="">
      </div>


      <div class="text-blue-900 font-bold mb-4 text-xl">A millennial struggles to lay down roots</div>
      <div class="text-blue-900 text mb-12 text-lg">
        Bash's friend, the daughter of the mother he'd just helped with her insurance claim, wanted to purchase a home of her own and asked him to help her with the process.
        She'd previously applied for pre-approval to three mortgage lenders. 
        All turned her down even though she met the standard qualifications to qualify for a home loan.
        
        <br/><br/>

        He researched alternative home ownership programs in her city and informed her of the Land Bank program where she could purchase a home for a few thousand dollars as a fixer upper.
        Bash put together her bid including itemized repair budget, submission letter and gathered support from community leaders on her behalf. 
        Her bid was soon approved and she purchased her first home for $3,000 in an up-and-coming hidden gem of a neighborhood.
      
        <br/><br/>

        We're currently filming and photographing her homeownership journey to share on our platform.
        Please check back soon for updates.
      </div>


      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 pt-24 pb-12 gap-4">
        <div class="w-full h-full col-span-2 row-span-2 overflow-hidden">
          <img class="w-full rounded object-cover" style="height: 25rem;" src="@/assets/story/birth/20201120_102521.jpg" alt="">
        </div>
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/20210323_125529.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/signal-2020-12-19-184901.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/IMG_20211222_152955810.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/20210331_143453.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/flyer.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/20201123_160256.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/IMG_20201017_152732.jpg" alt="">
        <img class="w-full rounded h-48 object-cover" src="@/assets/story/birth/20201120_102535.jpg" alt="">
      </div>


      <div class="text-blue-900 font-bold mb-4 text-xl">The birth of Homeplan</div>
      <div class="text-blue-900 text mb-12 text-lg">
        Real estate is fragmented, lacks transparency and is difficult to navigate. Homeowners lack education, have a difficult time finding trusted, affordable help and have even began to question the benefits of homeownership.
        63% of millennials, 50% of Gen X and 35% of boomers regret purchasing a home mainly due to the hidden cost of maintenance.

        <br/><br/>

        We asked ourselves a simple question - how could we help homeowners everywhere make homeownership simpler and more affordable? 
        After speaking to several hundred homeowners, we adopted the mantra "truth + transparency = trust" and set our sights on becoming the trusted partner homeowners desperately need.
        We've merged software with services to give homeowners a centralized place for better insights and more control over every aspect of their home.

        <br/><br/>

        Before launching as a company officially, we'd helped several homeowners solve their home related problems including roof replacement, insurance claims, tree removal, basic maintenance and appliance repair.
        We'd even helped someone become a first time homeowner after being turned down for a traditional mortgage.
        We secured our first partnership with a mortgage lender and added other mortgage and insurance companies to our pipeline. 
        During this time, Bash also became a homeowner and soon realized just how difficult it was to own a home.

        <br/><br/>

        We're community builders with backgrounds in technology and real estate who were asked by homeowners like you to solve this problem and we intend to do just that.
        We're excited for the future and look forward to adding value to your everyday life.
      </div>

      <div class="flex justify-center gap-2">
        <img class="w-48 md:w-80 object-contain" src="@/assets/signature/s-sig.png" alt="Bash's signature">
        <img class="w-40 md:w-72 object-contain" src="@/assets/signature/a-sig.png" alt="Alex's signature">
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>